// Login.tsx

import { auth } from "firebase/app";
import "firebase/auth";
import { navigate } from "gatsby";
import React from "react";
import { GrGoogle } from "react-icons/gr";
import { Box, Button } from "theme-ui";
import Routes from "../constants/routes";
import { AuthError, FirebaseContext } from "../services";

const LoginWidget: React.FC = () => {
  // get the variables we need for authentication.
  const { firebase, authToken, setAuthToken } =
    React.useContext(FirebaseContext);
  // setup some state variables for login
  const [email, setEmail] = React.useState<string>("");
  const [password, setPassword] = React.useState<string>("");

  // The method for handling google authentication
  const handleGoogleAuth = React.useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      try {
        event.preventDefault();
        const provider = new firebase.auth.GoogleAuthProvider();
        // get the credential from the google auth.
        const { credential, user } = await firebase
          .auth()
          .signInWithPopup(provider);
        // if we have a credential then get the access token and set it in state.

        if (credential) {
          // This has to be assigned to the oathcredential type so that we can get the accessToken property.

          const { accessToken } = credential as auth.OAuthCredential;
          setAuthToken(accessToken as string);
        }
      } catch (e) {
        console.log(e);
      }
    },
    [firebase, setAuthToken]
  );

  // Method for signing up and logging in.
  const handleSignupAndLogin = React.useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      let authError: AuthError | undefined;
      try {
        event.preventDefault();
        // Try to create a new user with the email and password.
        const { user } = await firebase
          .auth()
          .createUserWithEmailAndPassword(email, password);

        // If successful and we have a user the set the authToken.
        if (user) {
          const { refreshToken } = user;
          setAuthToken(refreshToken);
        }
        // If there is an error set the authError to the new error
      } catch (error) {
        authError = error;
      } finally {
        // If there is an authError and the code is that the email is already in use, try to sign
        // the user in with the email and password instead.

        if (authError?.code === "auth/email-already-in-use") {
          const { user } = await firebase
            .auth()
            .signInWithEmailAndPassword(email, password);
          // We've been here before... set the authToken if there is a user.

          if (user) {
            const { refreshToken } = user;
            setAuthToken(refreshToken);
          }
        }
      }
    },
    [email, password, firebase, setAuthToken]
  );

  const handleSignout = React.useCallback(
    async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      let authError: AuthError | undefined;
      try {
        event.preventDefault();
        firebase.auth().signOut();

        // If there is an error set the authError to the new error
      } catch (error) {
        authError = error;
      } finally {
        setAuthToken("");
      }
    },
    []
  );

  //   doSendEmailVerification = () =>
  //     this.auth.currentUser.sendEmailVerification({
  //       url: process.env.GATSBY_CONFIRMATION_EMAIL_REDIRECT,
  //     });

  // Effect that will reroute the user to the index.tsx file if there is an authToken
  React.useEffect(() => {
    if (authToken) {
      navigate(Routes.HOME);
    }
  }, [authToken]);

  return (
    (!authToken && (
      <Box as="form">
        <Button
          variant="secondary"
          sx={{ marginBottom: "20px" }}
          onClick={handleGoogleAuth}
        >
          <GrGoogle style={{ position: "absolute", marginTop: "3px" }} />
          <span style={{ marginLeft: "20px" }}>Sign in with Google</span>
        </Button>
      </Box>
    )) ||
    (authToken && (
      // redo this in a different section
      <div>
        <h1>Welcome</h1>
        <Button onClick={handleSignout}>Log out</Button>
      </div>
    ))
  );
};

export default LoginWidget;

import Layout from "../components/layout-main";
import LoginWidget from "../components/LoginWidget";

/** @jsx jsx */
import { jsx } from "theme-ui";

const LoginPage = ({ location }) => {
  return (
    <Layout location={location} pageTitle="Login">
      <section>
        <div className="aw-content">
          <div sx={{ maxWidth: "400px", margin: "0 auto" }}>
            <LoginWidget />
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default LoginPage;
